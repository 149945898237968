<template>
  <v-row cols="12" class="pt-5">
    <v-row cols="12">
      <v-col cols="12" sm="6" md="6">
        <v-select
          v-model="financeReportData.cutOffBy"
          :error-messages="financeReportError.cutOffBy"
          :items="cutOffItems"
          filled
          label="Cut Off By"
          dense
        ></v-select>
      </v-col>
    </v-row>

    <v-row cols="12">
      <v-col cols="12" sm="6" md="6">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              filled
              v-model="searchRptDate"
              :error-messages="financeReportError.dateRange"
              label="Search Date (YYYY-MM-DD)"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            range
            v-model="financeReportData.searchRptDateRange"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu2 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu2.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <div class="d-flex justify-end pa-3">
      <v-btn x-large color="primary" @click="generateFinanceReport()">
        Download
        <v-icon right> mdi-file-download-outline </v-icon>
      </v-btn>
    </div>
  </v-row>
</template>

<script>
export default {
  computed: {
    searchRptDate() {
      if (this.financeReportData.searchRptDateRange) {
        return this.financeReportData.searchRptDateRange.join(" - ");
      }

      return null;
    },
  },
  emits: ["generateFinanceReport"],
  props: {
    financeReportData: Object,
    financeReportError: Object,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,
    cutOffItems: ["Payment Received Date"],
  }),
  created() {
    //
  },
  methods: {
    generateFinanceReport() {
      this.$emit("generateFinanceReport", this.financeReportData);
    },
  },
};
</script>

<style>
#generate-report-table table,
#generate-report-table th,
#generate-report-table td {
  border: 1px solid black;
  border-collapse: collapse;
}

#generate-report-table th {
  background-color: #e0e4f4;
  padding-left: 10px;
  padding-right: 10px;
}

#generate-report-table td {
  padding-left: 10px;
  padding-right: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>