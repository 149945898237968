<template>
  <v-row cols="12" class="pa-5">
    <v-col cols="12" sm="6" md="6">
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            filled
            v-model="searchRptDate"
            :error-messages="generateReportError.dateRange"
            label="Search Date (YYYY-MM-DD)"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          range
          v-model="generateReportData.searchRptDateRange"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu1.save(date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>

    <v-col cols="12" sm="6" md="6">
      <v-btn x-large color="primary" @click="generateReport()">
        Generate
        <v-icon right> mdi-file-document-refresh </v-icon>
      </v-btn>
    </v-col>

    <!-- Report Summary -->
    <div v-if="showGenerateReport">
      <!-- <div> -->
      <div class="pb-3 d-flex justify-content-between align-items-center">
        <span> Report Summary: </span>
        <span>
          <v-btn color="info" dark @click="downloadReport()">
            Download &nbsp;
            <v-icon> mdi-download-circle </v-icon>
          </v-btn>
        </span>
      </div>

      <v-card>
        <v-col cols="12" md="12" sm="12" lg="12" justify="space-between">
          <div>
            <u class="h3">HRDC Report - taxPOD</u>
          </div>
        </v-col>

        <v-col cols="12" md="12" sm="12" lg="12" class="pb-0">
          <span class="h6">WEEK {{ generateReportData.week }}</span>
        </v-col>

        <v-col cols="12" md="8" sm="8" lg="8">
          <table style="width: 100%" id="generate-report-table">
            <tr>
              <th width="10%">No.</th>
              <th width="70%">Item</th>
              <th width="20%">Quantity</th>
            </tr>
            <tr
              v-for="(info, key1, index) in generateReportData.generalInfo"
              :key="index + key1"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ reportDesc[key1] }}</td>
              <td v-if="key1 == 'transFrHrdcToYyc'">
                <input v-model="transFrHrdcToYyc" />
              </td>
              <td v-else v-html="info"></td>
            </tr>
          </table>
        </v-col>

        <v-col cols="12" md="12" sm="12" lg="12" class="pb-0">
          <span class="h6">TO DATE - {{ generateReportData.weekEndDate }}</span>
        </v-col>

        <v-col cols="12" md="8" sm="8" lg="8">
          <table style="width: 100%" id="generate-report-table">
            <thead>
              <tr>
                <th width="10%">No.</th>
                <th width="70%">Item</th>
                <th width="20%">Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr class="font-weight-bold">
                <td>1</td>
                <td>
                  Ongoing Applications (including up to Claim Approved stage)
                </td>
                <td>{{ ongoingApplicationSum }}</td>
              </tr>
              <tr
                v-for="(
                  info, key2, index
                ) in generateReportData.ongoingApplications"
                :key="index + key2"
              >
                <td></td>
                <td>{{ ongoingApplications[key2] }}</td>
                <td v-if="key2 == 'pendingReqOfHrdcInvoice'">
                  <input type="number" v-model="pendingReqOfHrdcInvoice" />
                </td>
                <td :style="getBackgroundColor(key2)" v-else>{{ info }}</td>
              </tr>
              <tr v-for="i in 2" :key="i + 'ongoingApplications'">
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
            <tr class="font-weight-bold">
              <td>2</td>
              <td>Pending Refunds</td>
              <td>{{ pendingRefundSum }}</td>
            </tr>
            <tr
              v-for="(info2, key3, index2) in generateReportData.pendingRefunds"
              :key="index2 + key3"
            >
              <td></td>
              <td>{{ pendingRefunds[key3] }}</td>
              <td :style="getBackgroundColor(key3)">{{ info2 }}</td>
            </tr>
            <tr v-for="j in 1" :key="j + 'pendingRefunds'">
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr class="font-weight-bold">
              <td>3</td>
              <td>Pending taxPOD Account Enrollments</td>
              <td>{{ generateReportData.pendingAccountEnrollment }}</td>
            </tr>
          </table>
        </v-col>
      </v-card>
    </div>
  </v-row>
</template>

<script>
export default {
  computed: {
    searchRptDate() {
      if (this.generateReportData.searchRptDateRange) {
        return this.generateReportData.searchRptDateRange.join(" - ");
      }

      return null;
    },
    ongoingApplicationSum() {
      let sum = 0;
      for (const key in this.generateReportData.ongoingApplications) {
        if (
          typeof this.generateReportData.ongoingApplications[key] ===
            "number" &&
          !isNaN(this.generateReportData.ongoingApplications[key])
        ) {
          sum += this.generateReportData.ongoingApplications[key];
        }
      }

      if (this.pendingReqOfHrdcInvoice) {
        sum += Number(this.pendingReqOfHrdcInvoice);
      }

      return sum;
    },
    pendingRefundSum() {
      let sum = 0;
      for (const key in this.generateReportData.pendingRefunds) {
        if (
          typeof this.generateReportData.pendingRefunds[key] === "number" &&
          !isNaN(this.generateReportData.pendingRefunds[key])
        ) {
          sum += this.generateReportData.pendingRefunds[key];
        }
      }

      return sum;
    },
  },
  emits: ["generateReport", "downloadReport"],
  props: {
    generateReportData: Object,
    generateReportError: Object,
    showGenerateReport: Boolean,
    notReadyReportData: Array,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu1: false,
    transFrHrdcToYyc: null,
    pendingReqOfHrdcInvoice: null,
    reportDesc: {
      newApplications: "New Applications",
      resendApplications: "Resend Applications",
      grantsApproved: "Grant Approved",
      claimsSubmitted: "Claims Submitted",
      claimsApproved: "Claims Approved",
      transFrHrdcToYyc: "Transaction From HRDC to YYC",
    },
    ongoingApplications: {
      pendingGrantsApplicationOrApproval:
        "Pending Grants Application or Approval",
      grantsApprovedPendingForm:
        "Grant Approved, Pending Client to Revert Completed JD14 and T3 forms",
      pendingGrantsWithIssue: "Pending Grants with Issues",
      pendingSubscriptionEndToReqInvoice:
        "Pending End of Date of Subscription to Request for Invoice",
      pendingReqOfHrdcInvoice: "Pending Request of HRDC Invoice",
      pendingHrdcInvoiceToSubmitClaim:
        "Pending HRDC invoice from taxPOD Admin to Submit Claims",
      pendingSubscriptionEndToSubmitClaim:
        "Pending End of Date of Subscription to Submit Claim",
      pendingClaimSubmission: "Pending Claim Submission",
      claimsSubmitted: "Claims Submitted, Pending HRDC to Approve Claims",
      claimsApproved: "Claims Approved, Pending HRDC to Release Funds",
    },
    pendingRefunds: {
      pendingCnReq: "taxPOD Admin - Pending CN request",
      pendingCnApproval: "taxPOD Admin - Pending CN approval",
      pendingEmailSubmissionForRefund:
        "taxPOD Admin - Pending email submission for refund",
      pendingEmailApprovalForRefund:
        "taxPOD Admin - Pending email approval for refund",
      pendingRefund:
        "Finance - Pending Refund (taxPOD Admin have not receive payment advice)",
      failedRefund:
        "Failed Refund due to client banking details error, pending second refund",
      pendingEmailPAtoClient:
        "taxPOD Admin - Pending email payment advice to client",
    },
  }),
  created() {
    //
  },
  methods: {
    isInArray(key) {
      return this.notReadyReportData.includes(key);
    },
    getBackgroundColor(key) {
      return this.isInArray(key) ? { backgroundColor: "#f3b8b5" } : "";
    },
    generateReport() {
      this.$emit("generateReport", this.generateReportData);
    },
    downloadReport() {
      let sumData = {
        A: this.ongoingApplicationSum,
        B: this.pendingRefundSum,
        C: this.generateReportData.pendingAccountEnrollment,
      };

      this.generateReportData.generalInfo.transFrHrdcToYyc =
        this.transFrHrdcToYyc ?? 0;
      this.generateReportData.ongoingApplications.pendingReqOfHrdcInvoice =
        this.pendingReqOfHrdcInvoice;

      this.$emit("downloadReport", this.generateReportData, sumData);
    },
  },
};
</script>

<style>
#generate-report-table table,
#generate-report-table th,
#generate-report-table td {
  border: 1px solid black;
  border-collapse: collapse;
}

#generate-report-table th {
  background-color: #e0e4f4;
  padding-left: 10px;
  padding-right: 10px;
}

#generate-report-table td {
  padding-left: 10px;
  padding-right: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>